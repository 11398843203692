import axios from 'axios';
import {API_GATEWAY_SERVICE_URL} from 'Core/Const/Env';
import {EHTTPStatus} from 'Core/Enums';
import _ from 'lodash';
import {getAccessToken, getAccessTokenHeader} from 'Services/LocalStorage';

import {memoizedRefreshToken} from './refresh';

const previousCreate = axios.create;

axios.defaults.headers.common = {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
};

axios.defaults.baseURL = API_GATEWAY_SERVICE_URL;

axios.create = (config?) => {
    const result = previousCreate.call(this, config);
    _.forEach((axios.interceptors.request as any).handlers, (handler) => {
        result.interceptors.request.use(handler.fulfilled, handler.rejected);
    });
    _.forEach((axios.interceptors.response as any).handlers, (handler) => {
        result.interceptors.response.use(handler.fulfilled, handler.rejected);
    });
    return result;
};

axios.interceptors.request.use(
    async (config) => {
        if (getAccessToken()) {
            config.headers = {
                ...config.headers,
                ...getAccessTokenHeader(),
            };
        }

        return config;
    },
    async (error) => await Promise.reject(error)
);

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const config = error?.config;

        if (error?.response?.status === EHTTPStatus.UNAUTHORIZED && !config?.sent) {
            config.sent = true;

            await memoizedRefreshToken();

            if (getAccessToken()) {
                config.headers = {
                    ...config.headers,
                    ...getAccessTokenHeader(),
                };
            }

            return await axios(config);
        }
        return await Promise.reject(error);
    }
);

export default axios;
