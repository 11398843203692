export const getAccessToken = () => localStorage.getItem('x-access-token');

export const getRefreshToken = () => localStorage.getItem('x-refresh-token');

export const getAccessTokenHeader = () => ({Authorization: `Bearer ${getAccessToken()}`});

export const getRefreshTokenHeader = () => ({Authorization: `Bearer ${getRefreshToken()}`});

export const setAuthTokens = (accessToken: string, refreshToken: string) => {
    localStorage.setItem('x-access-token', accessToken);
    localStorage.setItem('x-refresh-token', refreshToken);
};

export const removeAuthTokens = () => {
    localStorage.removeItem('x-access-token');
    localStorage.removeItem('x-refresh-token');
};

export const tokensExist = () => getAccessToken() && getRefreshToken();
