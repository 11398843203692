import {FC} from 'react';

import {Col, Row, Typography} from 'antd';
import {observer} from 'mobx-react';
import useStores from 'Stores';

import './styles.scss';
import ChatMenuItem from '../Chat';

interface IProps {
    setIsMenuOpen: (arg: boolean) => void;
}

const ChatsWrapper: FC<IProps> = ({setIsMenuOpen}: IProps) => {
    const {chats} = useStores().historyStore;

    return (
        <Col className="recently-chats-wrapper">
            <Typography.Text strong style={{color: '#fff'}}>
                Недавно
            </Typography.Text>
            <Row className="recently-chats">
                <Col>
                    {chats.map((chat, i) => (
                        <ChatMenuItem chat={chat} setIsMenuOpen={setIsMenuOpen} key={i} />
                    ))}
                </Col>
            </Row>
        </Col>
    );
};

export default observer(ChatsWrapper);
