import React, {FunctionComponent} from 'react';

import {CheckCircleOutlined} from '@ant-design/icons';
import {Card, Row, Space, Typography} from 'antd';
import {observer} from 'mobx-react';

import {useAuth} from '../../../Services/Auth';
import PurchaseButton from '../PurchaseButton';
import './styles.scss';

interface IProps {
    id: string;
    title: string;
    description: string;
    price: number;
    currency: string;
    items: string[];
    highlighted?: boolean;
}
const PackCard: FunctionComponent<IProps> = ({id, title, description, items, price, currency, highlighted}) => {
    const {getPackPaymentLink} = useAuth();
    return (
        <Card className="pack-card" bordered>
            <>
                <Row justify="center">
                    <Typography.Text strong style={{fontSize: '20px', marginBottom: '10px'}}>
                        {title}
                    </Typography.Text>
                </Row>
                <Row justify="center">
                    <Space direction="vertical">
                        {/* <Row style={{marginBottom: '20px'}}>{description}</Row> */}

                        {items.map((option, i) => (
                            <Row style={{marginBottom: '15px'}} key={i}>
                                <CheckCircleOutlined style={{fontSize: '20px', marginRight: '4px', color: '#6C61ed'}} />
                                <Typography.Text>{option}</Typography.Text>
                            </Row>
                        ))}
                    </Space>
                </Row>
                <Row justify={'start'} style={{marginBottom: '20px'}}></Row>
                <Row justify="center">
                    <Typography.Text strong style={{fontSize: '20px', paddingBottom: '20px'}}>
                        {price} {currency}
                    </Typography.Text>

                    <div style={{width: '80%'}}>
                        <PurchaseButton
                            text="Купить"
                            size="small"
                            handleClick={async () => {
                                const paymentLink = await getPackPaymentLink(id);
                                window.open(paymentLink, '_blank');
                            }}
                        />
                    </div>
                </Row>
            </>
        </Card>
    );
};

export default observer(PackCard);
