import React, {ChangeEvent, FunctionComponent, ReactElement, useEffect, useRef, useState} from 'react';

import {DislikeOutlined, LikeOutlined, DeleteOutlined, SearchOutlined, EditOutlined} from '@ant-design/icons';
import {Button, Card, Col, Input, Row} from 'antd';
import {observer} from 'mobx-react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';

import ROUTES from '../../../Core/Const/Routes';
import {usePromptsListStore, usePromptStore, useSearchStore} from '../../../Services/Adapters/store';
import {usePrompts} from '../../../Services/Prompts';
import CreatePromptModal from '../CreatePromptModal';
import PromptEditor from '../PromptEditor';

import './styles.scss';

const Prompts: FunctionComponent = () => {
    const {promptId: paramPromptId} = useParams();
    const {resetMessages} = useSearchStore();
    const {prompts} = usePromptsListStore();
    const {prompt: selectedPrompt, setIsCreatePromptModalVisible, clearPrompt} = usePromptStore();
    const navigate = useNavigate();
    const {getPrompts, deletePrompt, getPrompt} = usePrompts();
    const bottomRef = useRef<HTMLDivElement>(null);
    const [isEditorOpen, setIsEditorOpen] = useState(false);

    useEffect(() => {
        void getPrompts();
    }, []);

    useEffect(() => {
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [prompts]);

    const handleOnClickCard = async (promptId: number): Promise<void> => {
        await getPrompt(promptId);
        navigate(`${ROUTES.APP.PATH}`);
    };

    const handleOnClickAddPrompt = (): void => {
        setIsCreatePromptModalVisible(true);
    };

    const handleOnChangePromptsSearch = async (e: ChangeEvent<HTMLInputElement>) => {
        await getPrompts(e.target.value);
    };

    const onClickEdit = async (promptId: number, e: {stopPropagation: () => void}) => {
        e.stopPropagation();
        setIsEditorOpen(true);

        if (promptId) await getPrompt(promptId);
    };

    const getPromptCardHeader = (promptId: number, title: string): ReactElement => {
        return (
            <Row justify="space-between">
                <Col className="prompt-card-title">{title}</Col>
                <EditOutlined onClick={async (e) => await onClickEdit(promptId, e)} />
                <DeleteOutlined
                    onClick={async (e) => {
                        e.stopPropagation();
                        await deletePrompt(promptId);

                        if (paramPromptId && promptId === parseInt(paramPromptId)) {
                            clearPrompt();
                            navigate(`${ROUTES.PROMPTS.PATH}`);
                        } else {
                            await getPrompts();
                        }
                    }}
                />
            </Row>
        );
    };

    return (
        <Col style={{width: '100%', height: '100%', background: '#f0f0f0'}}>
            <Row style={{width: '100%', padding: '1rem 5%'}} justify="center">
                <Input
                    className="w50 prompt-search"
                    suffix={<SearchOutlined />}
                    onChange={handleOnChangePromptsSearch}
                />
                <Button type="primary" className="add-prompt-btn" onClick={handleOnClickAddPrompt}>
                    + Создать
                </Button>
                <CreatePromptModal />
            </Row>
            <Col style={{height: '90%'}}>
                <Row style={{width: '100%', maxHeight: '85%', padding: '0 5%', overflow: 'auto'}}>
                    {prompts.map((prompt) => (
                        <>
                            <PromptEditor promptId={prompt.id} isOpen={isEditorOpen} setIsOpen={setIsEditorOpen} />
                            <Card
                                className={selectedPrompt?.id === prompt.id ? 'prompt-card active' : 'prompt-card'}
                                hoverable
                                title={getPromptCardHeader(prompt.id, prompt.title)}
                                key={prompt.id}
                                onClick={async () => {
                                    resetMessages();
                                    await handleOnClickCard(prompt.id);
                                }}
                                actions={[<LikeOutlined key={prompt.id} />, <DislikeOutlined key={prompt.id} />]}
                            >
                                <Row style={{minHeight: '50px'}}>{prompt.description}</Row>
                            </Card>
                        </>
                    ))}
                    <div ref={bottomRef} />
                </Row>
            </Col>
        </Col>
    );
};

export default observer(Prompts);
