import {FunctionComponent} from 'react';

import {Col, Row} from 'antd';
import {observer} from 'mobx-react';

import useStores from '../../../Stores';
import PackCard from '../PackCard';
import './index.scss';
import s from './style.module.less';

const ProfilePackages: FunctionComponent = () => {
    const {availablePacks} = useStores().profileStore;

    const getPackAiModel = (aiModel: any) => `${aiModel.requests_count} запросов к ${aiModel.title}`;

    return (
        <Row gutter={16} wrap className={s.wrapper}>
            {availablePacks.map((pack: any) => (
                <Col key={pack.id}>
                    <PackCard
                        id={pack.id}
                        title={pack.title}
                        description={pack.description}
                        price={pack.price}
                        currency={pack.currency}
                        items={pack.ai_models.map((aiModel: any) => getPackAiModel(aiModel))}
                    />
                </Col>
            ))}
        </Row>
    );
};

export default observer(ProfilePackages);
