import {FunctionComponent, RefObject, useEffect, useState} from 'react';

import {CopyOutlined, RobotOutlined} from '@ant-design/icons';
import {Avatar, Button, Col, Row, Image, Typography} from 'antd';
import {observer} from 'mobx-react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import useStores from 'Stores';

import {EAiMode, EMessageType, ERole} from '../../../Core/Enums';
import {delay} from '../../Utils';
import './styles.scss';

interface IProps {
    message: any;
    bottomRef?: RefObject<any>;
}

const Message: FunctionComponent<IProps> = ({message}: IProps) => {
    const bgColor = message.role === ERole.USER ? '#fff' : '#eef2fe';
    const avatar = message.role === ERole.USER ? 'Y' : <RobotOutlined />;
    const {modelsOptions} = useStores().profileStore;
    const [image, setImage] = useState(<></>);

    useEffect(() => {
        const messageContent = async (): Promise<void> => {
            const delayMs = 1000;

            if (message.type === EMessageType.IMAGE || message.mode === EAiMode.IMAGE_GENERATION) {
                await delay(delayMs);
                setImage(<Image src={message.content} />);
            }
        };

        void messageContent();
    }, [message]);

    const modelTitle =
        message.role === ERole.ASSISTANT
            ? modelsOptions.find((item) => {
                  return item.name === message.mode;
              })?.title
            : 'Вы';

    const handleOnClickCopy = async (): Promise<void> => {
        await navigator.clipboard.writeText(message.content);
    };

    return (
        <Row
            style={{
                display: 'block',
                width: '100%',
                background: bgColor,
                padding: '1rem 5%',
            }}
        >
            <Row justify="space-between" wrap>
                <Row align="top" className="w90">
                    <Row className="w100" align="middle">
                        <Avatar shape="square" icon={<>{avatar}</>} />
                        <Typography.Text className="model-title">{modelTitle}</Typography.Text>
                    </Row>
                    <Col style={{overflow: 'auto'}}>
                        {message.type === EMessageType.IMAGE || message.mode === EAiMode.IMAGE_GENERATION ? (
                            image
                        ) : (
                            <Row>
                                <ReactMarkdown
                                    rawSourcePos
                                    remarkPlugins={[remarkGfm]}
                                    rehypePlugins={[rehypeRaw]}
                                    className="typewriter"
                                >
                                    {message.content}
                                </ReactMarkdown>
                            </Row>
                        )}
                    </Col>
                </Row>
                {message.role === ERole.ASSISTANT ? (
                    <Row align="middle" justify="space-between" wrap={true}>
                        <Button
                            ghost
                            size="large"
                            icon={<CopyOutlined />}
                            className="message-action-btn"
                            onClick={handleOnClickCopy}
                        />
                    </Row>
                ) : (
                    <></>
                )}
            </Row>
        </Row>
    );
};

export default observer(Message);
