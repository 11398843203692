import {FunctionComponent, useEffect, useState} from 'react';

import {Button, Form, Input, Layout} from 'antd';
import {Content} from 'antd/es/layout/layout';
import {observer} from 'mobx-react';
import {useNavigate, useParams} from 'react-router-dom';

import ROUTES from '../../../../Core/Const/Routes';
import {EPageTitle} from '../../../../Core/Enums';
import {useAuth} from '../../../../Services/Auth';
import {FormHeader} from '../FormHeader';
import './styles.scss';

const PasswordResetForm: FunctionComponent = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const {resetPassword} = useAuth();
    const [passwordValue, setPasswordValue] = useState('');
    const [passwordConfirmValue, setPasswordConfirmValue] = useState('');
    const {token} = useParams();

    useEffect(() => {
        document.title = EPageTitle.PASSWORD_RESET;
    }, []);

    const handleOnClickSubmit = async (): Promise<void> => {
        const payload = {
            password: passwordValue,
            confirmed_password: passwordConfirmValue,
            confirmation_token: token,
        };
        void resetPassword(payload);
        navigate(ROUTES.UNAUTHORIZED.LOGIN.PATH);
    };

    return (
        <Layout className="auth-page">
            <Content className="auth-page__content">
                <Form form={form} className="auth-form" layout="vertical" name="nest-messages">
                    <FormHeader />
                    <Form.Item label="Пароль" name="password" rules={[{required: true}]} className="no-margin-bottom">
                        <Input.Password value={passwordValue} onChange={(e) => setPasswordValue(e.target.value)} />
                    </Form.Item>
                    {passwordValue ? (
                        <Form.Item
                            label="Подтверждение пароля"
                            name="password-confirm"
                            rules={[{required: true}]}
                            className="no-margin-bottom"
                        >
                            <Input.Password
                                value={passwordConfirmValue}
                                onChange={(e) => setPasswordConfirmValue(e.target.value)}
                            />
                        </Form.Item>
                    ) : null}
                    <Form.Item style={{width: '100%'}} className="no-margin-bottom">
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{marginTop: '10px', width: '100%'}}
                            onClick={handleOnClickSubmit}
                        >
                            Сменить
                        </Button>
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
};

export default observer(PasswordResetForm);
