import {
    AUTH_GET_AVAILABLE_PACKS,
    AUTH_LOGIN_CHANGE,
    AUTH_LOGIN_ENDPOINT,
    AUTH_LOGOUT_ENDPOINT,
    AUTH_PASSWORD_CHANGE,
    AUTH_REGISTRATION_ENDPOINT,
    AUTH_PASSWORD_RESTORE_ENDPOINT,
    AUTH_PASSWORD_RESET_ENDPOINT,
    AUTH_TOKEN_REFRESH_ENDPOINT,
    AUTH_USER_AI_MODEL,
    AUTH_USER_AI_MODEL_REQUESTS,
    AUTH_USER_DETAIL_ENDPOINT,
    AUTH_VERIFY_ACCESS_TOKEN_ENDPOINT,
    getPackPaymentUrl,
} from '../../Core/Const/Endpoints';
import {baseClient} from '../instance';
import {IAiModelsResponse} from './types';

export default class Auth {
    login = async (payload: any): Promise<any> => {
        return await baseClient.post(AUTH_LOGIN_ENDPOINT, payload);
    };

    logout = async (): Promise<any> => {
        return await baseClient.post(AUTH_LOGOUT_ENDPOINT);
    };

    changeLogin = async (newLogin: string): Promise<any> => {
        return await baseClient.put(AUTH_LOGIN_CHANGE, {
            new_login: newLogin,
        });
    };

    changePassword = async (newPassword: string): Promise<any> => {
        return await baseClient.put(AUTH_PASSWORD_CHANGE, {
            new_password: newPassword,
        });
    };

    registration = async (payload: any): Promise<any> => {
        return await baseClient.post(AUTH_REGISTRATION_ENDPOINT, payload);
    };

    passwordRestore = async (payload: any): Promise<any> => {
        return await baseClient.post(AUTH_PASSWORD_RESTORE_ENDPOINT, payload);
    };

    passwordReset = async (payload: any): Promise<any> => {
        return await baseClient.post(AUTH_PASSWORD_RESET_ENDPOINT, payload);
    };

    getUser = async (): Promise<any> => {
        return await baseClient.get(AUTH_USER_DETAIL_ENDPOINT);
    };

    getAvailablePacks = async (): Promise<any> => {
        return await baseClient.get(AUTH_GET_AVAILABLE_PACKS);
    };

    verifyAccessToken = async (): Promise<any> => {
        return await baseClient.post(AUTH_VERIFY_ACCESS_TOKEN_ENDPOINT);
    };

    refreshTokens = async (): Promise<any> => {
        return await baseClient.post(AUTH_TOKEN_REFRESH_ENDPOINT);
    };

    getAiModels = async (): Promise<any> => {
        return await baseClient.get(AUTH_USER_AI_MODEL);
    };

    getPackPaymentLink = async (packId: string): Promise<any> => {
        return await baseClient.post(getPackPaymentUrl(packId));
    };

    getAiModelsRequests = async (): Promise<IAiModelsResponse> => {
        return await baseClient.get(AUTH_USER_AI_MODEL_REQUESTS);
    };
}
