import {FunctionComponent, useEffect, useState} from 'react';

import {Button, Form, Input, Layout, Space} from 'antd';
import {Content} from 'antd/es/layout/layout';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';

import ROUTES from '../../../../Core/Const/Routes';
import {EPageTitle} from '../../../../Core/Enums';
import {useAuth} from '../../../../Services/Auth';
import {FormHeader} from '../FormHeader';
import './styles.scss';

const PasswordRestoreForm: FunctionComponent = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const {restorePassword} = useAuth();
    const [emailValue, setEmailValue] = useState('');

    useEffect(() => {
        document.title = EPageTitle.PASSWORD_RESTORE;
    }, []);

    const handleOnClickSubmit = async (): Promise<void> => {
        void form.validateFields().then(() => {
            const payload = {
                email: emailValue,
            };
            void restorePassword(payload);
            navigate(ROUTES.UNAUTHORIZED.PASSWORD_RESTORE_CONFIRM.PATH);
        });
    };

    const validateMessages = {
        // eslint-disable-next-line no-template-curly-in-string
        required: 'Поле не может быть пустым',
        types: {
            email: 'Некорректный email',
        },
    };

    return (
        <Layout className="auth-page">
            <Content className="auth-page__content">
                <Form
                    form={form}
                    className="auth-form"
                    layout="vertical"
                    name="nest-messages"
                    validateMessages={validateMessages}
                >
                    <FormHeader />
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{type: 'email'}, {required: true}]}
                        className="no-margin-bottom"
                    >
                        <Input
                            placeholder="email@example.com"
                            value={emailValue}
                            onChange={(e) => setEmailValue(e.target.value)}
                        />
                    </Form.Item>
                    <Space direction="vertical" align="center" style={{width: '100%'}}>
                        <Button
                            type="link"
                            style={{border: 'none', color: '#6c61ed'}}
                            onClick={async () => {
                                void handleOnClickSubmit();
                            }}
                        >
                            Восстановление
                        </Button>
                    </Space>
                </Form>
            </Content>
        </Layout>
    );
};

export default observer(PasswordRestoreForm);
