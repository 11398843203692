import {OPENAI_CHAT_ENDPOINT, YANDEX_CHAT_ENDPOINT} from '../Core/Const/Endpoints';
import {API_GATEWAY_SERVICE_URL} from '../Core/Const/Env';
import {getAccessTokenHeader} from '../Services/LocalStorage';

export async function* streamingGenerator(streamingResponse: Response) {
    const decoder = new TextDecoder();

    // Set up a new ReadableStream to read the response body
    // @ts-expect-error
    const reader = streamingResponse.body.getReader();

    // Read the response stream as chunks and append them to the chat log
    while (true) {
        const {done, value} = await reader.read();
        if (done) break;

        yield decoder.decode(value);
    }
}
export async function sendStreamingMessage(messages: Array<{role: string; content: string}>, model: string) {
    return await fetch(`${API_GATEWAY_SERVICE_URL}${OPENAI_CHAT_ENDPOINT}?model_name=${model}&streaming=true`, {
        method: 'POST',
        headers: {
            ...getAccessTokenHeader(),
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({model, messages}),
    });
}
export async function sendStreamingMessageYandex(messages: Array<{role: string; text: string}>, model: string) {
    return await fetch(`${API_GATEWAY_SERVICE_URL}${YANDEX_CHAT_ENDPOINT}?model_name=${model}&stream=true`, {
        method: 'POST',
        headers: {
            ...getAccessTokenHeader(),
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({model, messages}),
    });
}
