// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kt5kmg8IrvLawyMyYyAR {\n  rotate: -90deg;\n  margin-bottom: 6px;\n}\n.RSMq6nlbTCYqbsz71ViS {\n  width: 50%;\n}\n@media screen and (max-width: 800px) {\n  .RSMq6nlbTCYqbsz71ViS {\n    width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/UI/Components/ProfileBalances/styles.module.less"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,kBAAA;AACJ;AAEA;EAKI,UAAA;AAJJ;AAAI;EAAA;IACI,WAAA;EAGN;AACF","sourcesContent":[".progressLine {\n    rotate: -90deg;\n    margin-bottom: 6px;\n}\n\n.balanceCard {\n    @media screen and (max-width: 800px) {\n        width: 100%;\n    }\n\n    width: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressLine": "kt5kmg8IrvLawyMyYyAR",
	"balanceCard": "RSMq6nlbTCYqbsz71ViS"
};
export default ___CSS_LOADER_EXPORT___;
