import React, {FunctionComponent} from 'react';

import {ExclamationCircleOutlined} from '@ant-design/icons';
import {Modal, Typography} from 'antd';
import {observer} from 'mobx-react';

import './styles.scss';

interface IProps {
    open?: boolean;
    onOk?: () => void;
    onCancel?: () => void;
}

const SubscriptionWarningModal: FunctionComponent<IProps> = ({open, onOk, onCancel}: IProps) => {
    const renderTitle = () => (
        <>
            <ExclamationCircleOutlined style={{color: 'red', marginRight: '5px'}} />
            <Typography.Text strong>Подписка не активна!</Typography.Text>
        </>
    );

    return (
        <Modal
            title={renderTitle()}
            open={open}
            cancelButtonProps={{style: {display: 'none'}}}
            onOk={onOk}
            onCancel={onCancel}
        />
    );
};

export default observer(SubscriptionWarningModal);
