import {FunctionComponent} from 'react';

import {Tabs} from 'antd';
import {observer} from 'mobx-react';

import ProfileBalances from '../ProfileBalances';
import ProfilePackages from '../ProfilePackages';
import ProfileSettings from '../ProfileSettings';

import './index.scss';

const ProfileContent: FunctionComponent = () => {
    const items = [
        {
            label: 'Настройки',
            key: 'settings',
            children: <ProfileSettings />,
        },
        {
            label: 'Пакеты',
            key: 'packages',
            children: <ProfilePackages />,
        },
        {
            label: 'Остаток запросов',
            key: 'balances',
            children: <ProfileBalances />,
        },
    ];

    return <Tabs items={items} style={{paddingInline: 10}} centered defaultActiveKey="settings" />;
};

export default observer(ProfileContent);
