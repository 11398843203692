import {FunctionComponent} from 'react';

import {LoginOutlined} from '@ant-design/icons';
import {Typography} from 'antd';
import {observer} from 'mobx-react';
import './styles.scss';

export const FormHeader: FunctionComponent = () => {
    return (
        <>
            <Typography.Title className="form-header__icon">
                <LoginOutlined />
            </Typography.Title>
            <Typography.Title className="form-header__title">AI Rabbit</Typography.Title>
        </>
    );
};

export default observer(FormHeader);
