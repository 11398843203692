import {FunctionComponent, ReactNode} from 'react';

import {PlusOutlined, SendOutlined} from '@ant-design/icons';
import {Button, Image, Row, Select, Typography} from 'antd';
import ROUTES from 'Core/Const/Routes';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router';

import {useSearchStore} from '../../../Services/Adapters/store';
import useStores from '../../../Stores';

import '../InputWrapper/styles.scss';

interface IProps {
    children?: ReactNode;
    onClickSend: () => Promise<void>;
    resetControl: ReactNode;
}

const InputWrapper: FunctionComponent<IProps> = ({children, onClickSend, resetControl}: IProps) => {
    const {isLoading, aiMode, setAiMode} = useSearchStore();
    const {modelsOptions} = useStores().profileStore;
    // const {setIsCreatePromptModalVisible} = usePromptStore();
    const {resetMessages, setIsLoading} = useStores().searchStore;
    const {setPrompt} = useStores().promptStore;
    const {setCurrentHistoryChatId} = useStores().historyStore;
    const navigate = useNavigate();

    // const getAiModelsGroups = () => {
    //     const aiModelsGroups: Array<{
    //         id: string;
    //         name: string;
    //         models: Array<{id: string; name: string; title: string}>;
    //     }> = [];

    //     modelsOptions.forEach(({id, name, title, ai}) => {
    //         if (!aiModelsGroups.length) aiModelsGroups.push({id: ai.id, name: ai.title, models: [{id, name, title}]});
    //         else {
    //             const existingAiModelGroupIndex = aiModelsGroups.findIndex(({id: groupId}) => ai.id === groupId);

    //             if (existingAiModelGroupIndex !== -1)
    //                 aiModelsGroups[existingAiModelGroupIndex]?.models.push({id: ai.id, name, title});
    //             else {
    //                 aiModelsGroups.push({id: ai.id, name: ai.title, models: [{id, name, title}]});
    //             }
    //         }
    //     });

    //     return aiModelsGroups;
    // };

    // const menuGroups = getAiModelsGroups();
    // const menuGroupsOptions = menuGroups.map(({name, models}) => ({
    //     label: name,
    //     options: models.map((model) => ({
    //         label: model.title,
    //         value: model.name,
    //     })),
    // }));

    type TModelName =
        | 'gpt-4'
        | 'gpt-4-1106-preview'
        | 'gpt-4o'
        | 'gpt-4o-mini'
        | 'gpt-3.5-turbo'
        | 'dall-e-3'
        | 'dall-e-2'
        | 'yandexgpt-lite'
        | 'yandexgpt'
        | 'summarization'
        | 'text-to-image';

    const renderModelTitle = (title: string, name: TModelName) => {
        const mappedImg = {
            'gpt-4': require('Assets/svg/gpt-4.svg').default,
            'gpt-4-1106-preview': require('Assets/svg/gpt-4-1106-preview.svg').default,
            'gpt-4o': require('Assets/svg/gpt-4o.svg').default,
            'gpt-4o-mini': require('Assets/svg/gpt-4o-mini.svg').default,
            'gpt-3.5-turbo': require('Assets/svg/gpt-3.5-turbo.svg').default,
            'dall-e-3': require('Assets/svg/dall-e-3.svg').default,
            'dall-e-2': require('Assets/svg/dall-e-2.svg').default,
            'yandexgpt-lite': require('Assets/svg/yandexgpt-lite.svg').default,
            yandexgpt: require('Assets/svg/yandexgpt.svg').default,
            summarization: require('Assets/svg/yandex-sum.svg').default,
            'text-to-image': require('Assets/svg/text-to-image.svg').default,
        };

        return (
            <>
                <Image style={{marginBottom: 4}} src={mappedImg[name]} preview={false} />
                <Typography.Text style={{marginLeft: 5, color: '#6C61ed'}}>{title}</Typography.Text>
            </>
        );
    };

    const mappedModelsOptions = modelsOptions.map(({name, title}) => ({
        label: renderModelTitle(title, name),
        value: name,
    }));

    const renderAiModeSelector = (): React.ReactElement => {
        return (
            <Select
                value={aiMode}
                bordered={false}
                suffixIcon={null}
                style={{width: 'max-content', minWidth: 100, border: 'none'}}
                dropdownStyle={{width: 200}}
                className="model-selector"
                onChange={(value) => setAiMode(value)}
                options={mappedModelsOptions}
            />
        );
    };

    return (
        <Row className="message-input">
            <Row className="message-input__controls-wrapper">
                <Row className="message-input__btn-block" justify="space-between" align="middle">
                    {renderAiModeSelector()}
                    {resetControl}
                    <Button
                        type="link"
                        icon={<PlusOutlined />}
                        style={{color: '#6C61ed'}}
                        onClick={() => {
                            setIsLoading(false);
                            resetMessages();
                            setPrompt(null);
                            setCurrentHistoryChatId(null);
                            navigate(ROUTES.APP.PATH);
                        }}
                    >
                        новый чат
                    </Button>
                </Row>
                <Row
                    style={{
                        width: '100%',
                        minHeight: '50px',
                        border: '1px solid #6c61ed',
                        borderRadius: '40px',
                        background: '#fff',
                        overflow: 'auto',
                        padding: '0 10px',
                    }}
                    wrap={false}
                    align="middle"
                >
                    {children}
                    <Row style={{width: '4%', alignItems: 'center'}} justify="center">
                        <Button
                            loading={isLoading}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: 'none',
                                background: 'none',
                                color: '#6c61ed',
                            }}
                            type="text"
                            icon={<SendOutlined style={{fontSize: '20px'}} onClick={onClickSend} />}
                        />
                    </Row>
                </Row>
            </Row>
        </Row>
    );
};

export default observer(InputWrapper);
