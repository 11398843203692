import {FunctionComponent} from 'react';

import {Col, Row} from 'antd';
import {observer} from 'mobx-react';

import ProfileSettingsChangePassword from '../ProfileSettingsChangePassword';
import ProfileSettingsInfo from '../ProfileSettingsInfo';
import './index.scss';
import s from './style.module.less';

const ProfileSettings: FunctionComponent = () => {
    return (
        <Row gutter={16} wrap className={s.wrapper} justify="center">
            <Col>
                <ProfileSettingsInfo />
            </Col>
            <Col>
                <ProfileSettingsChangePassword />
            </Col>
        </Row>
    );
};

export default observer(ProfileSettings);
