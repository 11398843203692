// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 800px) {\n  .vI0TMjeddP10mqLB28T4 {\n    width: 100%;\n    padding-inline: 20px 10px;\n  }\n}\n@media (max-width: 800px) {\n  .ant-drawer-content-wrapper {\n    width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/UI/Components/ProfileSettings/style.module.less"],"names":[],"mappings":"AACI;EAAA;IACI,WAAA;IACA,yBAAA;EACN;AACF;AAGI;EAAA;IACI,WAAA;EAAN;AACF","sourcesContent":[".wrapper {\n    @media (max-width: 800px) {\n        width: 100%;\n        padding-inline: 20px 10px;\n    }\n}\n\n:global(.ant-drawer-content-wrapper) {\n    @media (max-width: 800px) {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "vI0TMjeddP10mqLB28T4"
};
export default ___CSS_LOADER_EXPORT___;
