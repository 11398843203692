// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".R_23KzaRHij2mDZhPibQ {\n  min-width: 300px;\n}\n@media (max-width: 800px) {\n  .R_23KzaRHij2mDZhPibQ {\n    width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/UI/Components/ProfileSettingsInfo/style.module.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AACI;EAAA;IACI,WAAA;EAEN;AACF","sourcesContent":[".wrapper {\n    min-width: 300px;\n\n    @media (max-width: 800px) {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "R_23KzaRHij2mDZhPibQ"
};
export default ___CSS_LOADER_EXPORT___;
