import axios from 'axios';
import {AUTH_TOKEN_REFRESH_ENDPOINT} from 'Core/Const/Endpoints';
import {API_GATEWAY_SERVICE_URL} from 'Core/Const/Env';
import mem from 'mem';
import {removeAuthTokens, setAuthTokens, getRefreshTokenHeader} from 'Services/LocalStorage';

const innerAxios = axios.create({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
    },
    baseURL: API_GATEWAY_SERVICE_URL,
});

const refreshAuthTokens = async () => {
    try {
        const {data} = await innerAxios.post(AUTH_TOKEN_REFRESH_ENDPOINT, null, {
            headers: {...getRefreshTokenHeader()},
        });

        if (!data.access_token && !data.refresh_token) removeAuthTokens();

        setAuthTokens(data.access_token, data.refresh_token);
    } catch (error) {
        removeAuthTokens();
    }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshAuthTokens, {maxAge});
