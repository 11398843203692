import React, {FunctionComponent, ReactNode, useEffect, useState} from 'react';

import {MenuOutlined, UserOutlined} from '@ant-design/icons';
import {Avatar, Button, Dropdown, Layout, Menu, Row} from 'antd';
import {observer} from 'mobx-react';
import {useNavigate, useLocation} from 'react-router-dom';

import ROUTES from '../../../Core/Const/Routes';
import {useAuth} from '../../../Services/Auth';
import {usePrompts} from '../../../Services/Prompts';
import SubscriptionWarningModal from '../SubscriptionWarningModal';
import MenuDrawer from './Menu';
import './styles.scss';

const {Header, Content, Footer} = Layout;

interface IProps {
    children?: ReactNode;
    footer?: ReactNode;
}

const PageWrapper: FunctionComponent<IProps> = ({children, footer}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {logout} = useAuth();
    const {getHistory} = usePrompts();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

    useEffect(() => {
        void getHistory();
    }, []);

    const menuItemProfileChildren = (
        <Menu
            items={[
                {
                    key: ROUTES.PROFILE.PATH,
                    label: 'Управление',
                    onClick: () => {
                        navigate(ROUTES.PROFILE.PATH);
                    },
                },
                {
                    key: '/login',
                    label: <>Выход</>,
                    onClick: () => {
                        void logout();
                        navigate(ROUTES.UNAUTHORIZED.LOGIN.PATH);
                    },
                },
            ]}
        />
    );

    // const createMenuItems = (items: any[]): any =>
    //     items.map((item) => ({
    //         key: item.route,
    //         label: item.title,
    //         icon: item.icon ? <item.icon /> : null,
    //         children: item.children ? createMenuItems(item.children) : null,
    //         onClick: item.route === ROUTES.UNAUTHORIZED.LOGIN.PATH ? async () => await logout() : null,
    //     }));

    // const isMenuItemPrivate = (selectedItem: any, items: any[]): boolean =>
    //     items.find((item) => {
    //         if (item.route === selectedItem.key) return item.private;
    //         if (item.children) return isMenuItemPrivate(selectedItem, item.children);
    //         return false;
    //     });

    // const selectedMenuItem = location.pathname;
    // const menuItems = createMenuItems(MENU_ITEMS);
    //
    // const handleClick = (selectedItem: MenuInfo): void => {
    //     const hasPageAccess = hasSubscription || !isMenuItemPrivate(selectedItem, MENU_ITEMS);
    //
    //     if (hasPageAccess) navigate(selectedItem.key);
    //     else setIsWarningModalOpen(true);
    // };

    const handleModal = () => {
        setIsWarningModalOpen(false);
    };

    return (
        <Layout className="layout">
            <MenuDrawer isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />
            <Layout>
                <Header className="layout__header">
                    <Row justify="space-between" align="middle" className="w100" style={{padding: '10px 20px'}}>
                        <Row align="middle">
                            <MenuOutlined className="layout__header__menu-icon" onClick={() => setIsMenuOpen(true)} />
                            <Button
                                style={{padding: '0 10px', height: '25px', fontSize: '12px'}}
                                onClick={() => {
                                    location.pathname.includes('/prompt')
                                        ? navigate(ROUTES.APP.PATH)
                                        : navigate(ROUTES.PROMPTS.PATH);
                                }}
                            >
                                {location.pathname.includes('/prompt') ? 'Чат' : 'Шаблоны запросов'}
                            </Button>
                        </Row>
                        <Dropdown overlay={menuItemProfileChildren} trigger={['click']}>
                            <Avatar style={{cursor: 'pointer'}}>
                                <UserOutlined />
                            </Avatar>
                        </Dropdown>
                    </Row>
                </Header>
                <Content className="layout__content">
                    <div className="layout__content__container">{children}</div>
                </Content>
                {footer ? <Footer className="layout__content__footer">{footer}</Footer> : null}
                <SubscriptionWarningModal open={isWarningModalOpen} onOk={handleModal} onCancel={handleModal} />
            </Layout>
        </Layout>
    );
};

export default observer(PageWrapper);
