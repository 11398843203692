import {FunctionComponent} from 'react';

import {Card, Space, Typography} from 'antd';
import {observer} from 'mobx-react';
import useStores from 'Stores';

import './index.scss';
import s from './style.module.less';

const {Text} = Typography;

const ProfileSettingsInfo: FunctionComponent = () => {
    const {login, email} = useStores().profileStore;

    return (
        <Card title="Информация" bordered={false} className={s.wrapper}>
            <Space direction="vertical">
                <Space>
                    <Text disabled>Login: </Text>
                    <Text>{login}</Text>
                </Space>
                <Space>
                    <Text disabled>Email: </Text>
                    <Text>{email}</Text>
                </Space>
            </Space>
        </Card>
    );
};

export default observer(ProfileSettingsInfo);
