import React, {FC, useState} from 'react';

import {DeleteOutlined} from '@ant-design/icons';
import {Row, Typography} from 'antd';
import ROUTES from 'Core/Const/Routes';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';
import {usePrompts} from 'Services/Prompts';
import useStores from 'Stores';
import './styles.scss';

interface IProps {
    chat: any;
    setIsMenuOpen: (arg: boolean) => void;
}

const ChatMenuItem: FC<IProps> = ({chat, setIsMenuOpen}: IProps) => {
    const {setCurrentHistoryChatId} = useStores().historyStore;
    const {getHistoryChat, deleteChatFromHistory, getHistory} = usePrompts();
    const navigate = useNavigate();
    const [isDeleteVisible, setIsDeleteVisible] = useState(false);

    return (
        <Row
            className="chat-menu-item-wrapper"
            onMouseEnter={() => setIsDeleteVisible(true)}
            onMouseLeave={() => setIsDeleteVisible(false)}
        >
            <Typography.Text
                strong
                className="chat-menu-item-title"
                onClick={async () => {
                    setIsMenuOpen(false);
                    setCurrentHistoryChatId(chat.id);
                    await getHistoryChat(chat.id);
                    navigate(ROUTES.APP.PATH);
                }}
            >
                {chat.title}
            </Typography.Text>
            {isDeleteVisible ? (
                <DeleteOutlined
                    className="chat-menu-item-delete-btn"
                    onClick={async () => {
                        await deleteChatFromHistory(chat.id);
                        await getHistory();
                    }}
                />
            ) : null}
        </Row>
    );
};

export default observer(ChatMenuItem);
