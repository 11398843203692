import {sendStreamingMessageYandex, streamingGenerator} from '../../Api/stream';
import YandexAPI from '../../Api/Yandex';
import {EHTTPStatus, EMessageType, ERole} from '../../Core/Enums';
import {useSearchStore} from '../Adapters/store';

// eslint-disable-next-line  @typescript-eslint/explicit-function-return-type
export const useYandexChat = () => {
    const api = new YandexAPI();
    const {
        messages,
        setIsLoading,
        appendTextToCurrentMessage,
        setCurrentMessage,
        addMessage,
        aiMode,
        setIsAiAnswering,
    } = useSearchStore();

    return {
        async sendChatMessage() {
            setIsLoading(true);
            const sendingMessages = messages.map(({role, text}) => {
                return {role, text};
            });
            try {
                const response = await api.sendChatMessage(sendingMessages);
                setIsLoading(false);
                return response;
            } catch (e: any) {
                if (e.data.response.status === EHTTPStatus.UNAUTHORIZED) {
                    const response = await api.sendChatMessage(sendingMessages);
                    setIsLoading(false);
                    return response;
                }
            }
        },
        async sendChatMessageInStreamingModeYandex() {
            setIsAiAnswering(true);

            const lastEndOfContextIndex = messages.map(({role}) => role).lastIndexOf(ERole.CONTEXT_END);
            const sendingMessages: Array<{role: string; text: string}> = messages
                .slice(lastEndOfContextIndex + 1)
                .map(({role, content}) => ({role, text: content}));

            if (sendingMessages.length === 0) {
                setIsAiAnswering(false);
                return;
            }

            const sg = streamingGenerator(await sendStreamingMessageYandex(sendingMessages, aiMode));

            // @ts-expect-error
            let response: IteratorResult<string> = {};
            setCurrentMessage(ERole.ASSISTANT, '', aiMode, EMessageType.TEXT);
            addMessage();

            do {
                response = await sg.next();

                if (response?.value) appendTextToCurrentMessage(response.value);
            } while (response && !response.done);
            setIsAiAnswering(false);
        },
    };
};
