import {YANDEX_CHAT_ENDPOINT} from '../../Core/Const/Endpoints';
import {getAccessTokenHeader} from '../../Services/LocalStorage';
import {baseClient} from '../instance';
import {IChatMessageBackend} from './types';

export default class YandexAPI {
    sendChatMessage = async (messages: IChatMessageBackend[]): Promise<any> => {
        return await baseClient.post(YANDEX_CHAT_ENDPOINT, {messages}, {headers: getAccessTokenHeader()});
    };
}
