import {OPENAI_CHAT_ENDPOINT, OPENAI_TEXT_TO_IMAGE_ENDPOINT} from '../../Core/Const/Endpoints';
import {getAccessTokenHeader} from '../../Services/LocalStorage';
import {baseClient} from '../instance';
import {IChatMessageBackend, IOpenaiTextToImageRequest, IOpenaiTextToImageResponse} from './types';

export default class OpenaiAPI {
    sendChatMessage = async (messages: IChatMessageBackend[]): Promise<any> => {
        return await baseClient.post(OPENAI_CHAT_ENDPOINT, {messages}, {headers: getAccessTokenHeader()});
    };

    textToImage = async (
        payload: IOpenaiTextToImageRequest,
        modelName: string
    ): Promise<IOpenaiTextToImageResponse> => {
        const response = await baseClient.post(`${OPENAI_TEXT_TO_IMAGE_ENDPOINT}?model_name=${modelName}`, payload);
        return {imgUrl: response.data.img_url};
    };
}
