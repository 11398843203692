// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 800px) {\n  .rmqbrdzVjsWdIdcXggiS {\n    justify-content: center;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/UI/Components/ProfilePackages/style.module.less"],"names":[],"mappings":"AACI;EAAA;IACI,uBAAA;EACN;AACF","sourcesContent":[".wrapper {\n    @media (max-width: 800px) {\n        justify-content: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "rmqbrdzVjsWdIdcXggiS"
};
export default ___CSS_LOADER_EXPORT___;
