import {FunctionComponent, useState} from 'react';

import {QuestionCircleOutlined} from '@ant-design/icons';
import {Input, Modal, Row, Tooltip} from 'antd';
import {InputStatus} from 'antd/es/_util/statusUtils';
import TextArea from 'antd/es/input/TextArea';
import {observer} from 'mobx-react';

import {usePromptStore} from '../../../Services/Adapters/store';
import {usePrompts} from '../../../Services/Prompts';

const CreatePromptModal: FunctionComponent = () => {
    const {
        savingPromptTitle,
        setSavingPromptTitle,
        setSavingPromptDescription,
        setSavingPromptTemplate,
        isCreatePromptModalVisible,
        setIsCreatePromptModalVisible,
    } = usePromptStore();
    const {createPrompt, getPrompts} = usePrompts();
    const [titleInputStatus, setTitleInputStatus] = useState<InputStatus>();

    const inputTitlePlaceholder = 'Введите название';
    const inputDescriptionPlaceholder = 'Введите описание';
    const inputPromptTemplatePlaceholder = 'Проверь код [вставьте код]';
    const promptTemplateTooltipText = `Введите текст, указав в [...] варьируемую часть запроса.
    Например, напишите: "проверь код [вставьте код]"`;

    const handleOnCloseModal = (): void => {
        setIsCreatePromptModalVisible(false);
    };

    const validatePromptTitle = (): boolean => {
        if (!savingPromptTitle.trim()) {
            setTitleInputStatus('error');
            return false;
        }
        return true;
    };

    const handleOnChangeTitle = (e: any): void => {
        const title = e.target.value;
        setSavingPromptTitle(title);
        !title.trim() ? setTitleInputStatus('error') : setTitleInputStatus('');
    };

    const handleOnChangeDescription = (e: any): void => {
        setSavingPromptDescription(e.target.value);
    };

    const handleOnChangeTemplate = (e: any): void => {
        setSavingPromptTemplate(e.target.value);
    };

    const handleSubmit = async (): Promise<void> => {
        if (validatePromptTitle()) {
            setIsCreatePromptModalVisible(false);
            setTitleInputStatus('');
            await createPrompt();
            await getPrompts();
        }
    };

    return (
        <Modal
            title="Создать новый шаблон"
            open={isCreatePromptModalVisible}
            cancelButtonProps={{style: {display: 'none'}}}
            onOk={handleSubmit}
            okButtonProps={{style: {margin: 0, width: '100%'}}}
            okText="Создать!"
            onCancel={handleOnCloseModal}
            destroyOnClose
        >
            <Row style={{paddingBottom: '20px'}}>
                <div>Название</div>
                <Input status={titleInputStatus} onChange={handleOnChangeTitle} placeholder={inputTitlePlaceholder} />
            </Row>
            <Row style={{paddingBottom: '20px'}}>
                <div>Описание</div>
                <Input onChange={handleOnChangeDescription} placeholder={inputDescriptionPlaceholder} />
            </Row>
            <Row style={{paddingBottom: '20px'}}>
                <div>
                    <span>Текст запроса</span>
                    <Tooltip title={promptTemplateTooltipText} color="#6c61ed">
                        <QuestionCircleOutlined style={{marginLeft: '5px', color: '#bdbdbd'}} />
                    </Tooltip>
                </div>
                <TextArea rows={2} onChange={handleOnChangeTemplate} placeholder={inputPromptTemplatePlaceholder} />
            </Row>
        </Modal>
    );
};

export default observer(CreatePromptModal);
