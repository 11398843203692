import React, {FunctionComponent} from 'react';

import {Button} from 'antd';
import {observer} from 'mobx-react';

interface IProps {
    text: string;
    size: 'large' | 'small';
    handleClick?: () => any;
}
const PurchaseButton: FunctionComponent<IProps> = ({text, size, handleClick}: IProps) => {
    const baseStyle = {
        width: '100%',
        color: 'white',
        borderRadius: '10px',
        border: 'none',
        background: 'linear-gradient(90deg, rgba(237,173,97,1) 0%, rgba(108,97,237,1) 50%, rgba(178,97,237,1) 100%)',
    };
    const buttonStyle = (() => {
        if (size === 'large')
            return {
                ...baseStyle,
                height: '50px',
            };

        if (size === 'small')
            return {
                ...baseStyle,
                height: '40px',
            };
        return baseStyle;
    })();

    return (
        <Button size={size} style={{...buttonStyle}} onClick={handleClick}>
            {text}
        </Button>
    );
};

export default observer(PurchaseButton);
