import {FunctionComponent, ReactElement} from 'react';

import ROUTES from 'Core/Const/Routes';
import {observer} from 'mobx-react';
import {useLocation} from 'react-router';
import {Navigate} from 'react-router-dom';
import {tokensExist} from 'Services/LocalStorage';

import useStores from '../../../../Stores';

interface IProps {
    children: ReactElement;
}

const PrivateRoute: FunctionComponent<IProps> = ({children}: IProps) => {
    const location = useLocation();
    const {hasSubscription} = useStores().profileStore;

    const isAuth = tokensExist();
    const hasPageAccess = hasSubscription || location.pathname.includes(ROUTES.PROFILE.PATH);

    if (isAuth) {
        if (hasPageAccess) return children;
        else return <Navigate to={ROUTES.PROFILE.PATH} />;
    } else return <Navigate to={ROUTES.UNAUTHORIZED.LOGIN.PATH} />;
};

export default observer(PrivateRoute);
