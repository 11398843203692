import {FunctionComponent, useEffect, useState} from 'react';

import {ConfigProvider} from 'antd';
import AppRouter from 'Core/AppRouter';
import {observer} from 'mobx-react';
import {useAuth} from 'Services/Auth';
// import {tokensExist} from 'Services/LocalStorage';
import Loader from 'UI/Components/Loader';

import 'UI/Styles/app.less';

const App: FunctionComponent = () => {
    const {tryAuthenticate} = useAuth();

    const [isLoading, setIsLoading] = useState(true);

    const init = async () => {
        await tryAuthenticate();
        // if (tokensExist()) await getSubscriptionStatus();
        setIsLoading(false);
    };

    useEffect(() => {
        void init();
    }, []);

    if (isLoading) return <Loader />;
    else
        return (
            <ConfigProvider theme={{token: {colorPrimary: '#6C61ed'}}}>
                <AppRouter />
            </ConfigProvider>
        );
};

export default observer(App);
