import {FunctionComponent} from 'react';

import {ReloadOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import {observer} from 'mobx-react';

import {EMessageType, ERole} from '../../../Core/Enums';
import {useSearchStore} from '../../../Services/Adapters/store';
import './styles.scss';
import {usePrompts} from '../../../Services/Prompts';
import useStores from '../../../Stores';

const DialogueReset: FunctionComponent = () => {
    const {setCurrentMessage, messages, addMessage} = useSearchStore();
    const {currentHistoryChatId} = useStores().historyStore;
    const {updateHistoryChat} = usePrompts();

    const handleClickReset = async (): Promise<void> => {
        setCurrentMessage(ERole.CONTEXT_END, '', '', EMessageType.TEXT);
        addMessage();
        if (currentHistoryChatId) await updateHistoryChat(currentHistoryChatId);
    };

    const hasHistory = messages.length > 0;
    const isContextEnd = messages[messages.length - 1]?.role === ERole.CONTEXT_END;
    const isResetDisabled = !hasHistory || isContextEnd;

    return (
        <Button
            type="text"
            ghost
            className="dialogue-reset-btn"
            icon={<ReloadOutlined />}
            onClick={handleClickReset}
            disabled={isResetDisabled}
        >
            сбросить диалог
        </Button>
    );
};

export default observer(DialogueReset);
