import React, {FunctionComponent} from 'react';

import {Layout, Spin} from 'antd';
import {Content} from 'antd/es/layout/layout';
import {observer} from 'mobx-react';

import './styles.scss';

const Loader: FunctionComponent = () => {
    return (
        <Layout style={{height: '98vh', width: '100%', background: 'white'}}>
            <Content style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                <Spin spinning={true} />
            </Content>
        </Layout>
    );
};

export default observer(Loader);
