import React, {FunctionComponent, useEffect, useRef, useState} from 'react';

import {LoadingOutlined, RobotOutlined} from '@ant-design/icons';
import {Avatar, Empty, Row, Space} from 'antd';
import {observer} from 'mobx-react';
import ReactMarkdown from 'react-markdown';

import {AnswerMode} from '../../../Core/Enums';
import {useSearchStore} from '../../../Services/Adapters/store';
import {delay} from '../../Utils';
import Message from '../Message';
import './styles.scss';

const MessageContainer: FunctionComponent = () => {
    const bottomRef = useRef<HTMLDivElement>(null);
    const {message, messages, isLoading, aiMode} = useSearchStore();
    const [preLoaderText, setPreLoaderText] = useState('');

    useEffect(() => {
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [message, messages]);

    useEffect(() => {
        const getPreloader = async (): Promise<void> => {
            const delayMs = 4000;

            if (aiMode === AnswerMode.DALLE_2 || aiMode === AnswerMode.DALLE_3 || aiMode === AnswerMode.TEXT_TO_IMAGE) {
                setPreLoaderText('создаю изображение...');
                await delay(delayMs);
                setPreLoaderText('улучшаю качество изображения...');
            } else {
                setPreLoaderText('формулирую лучший ответ...');
            }
        };

        void getPreloader();
    }, [message]);

    return (
        <Space.Compact block style={{height: '100%'}}>
            <div style={{width: '100%', height: '100%', overflow: 'auto'}}>
                {messages.length > 0 ? (
                    messages.map((message, index) =>
                        message.role === 'end-of-context' ? (
                            <div key={index} className="end-of-context">
                                Конец диалога
                            </div>
                        ) : (
                            <Message key={index} message={message} bottomRef={bottomRef} />
                        )
                    )
                ) : (
                    <div className="empty-container">
                        <Empty description={false} style={{color: '#6c61ed'}} />
                    </div>
                )}
                {isLoading ? (
                    <Row
                        style={{
                            display: 'block',
                            width: '100%',
                            background: '#eef2fe',
                            padding: '1rem 5%',
                        }}
                    >
                        <Row justify="space-between" wrap={true}>
                            <Row align="top">
                                <Avatar shape="square" style={{margin: '1rem 1rem 1rem 0'}} icon={<RobotOutlined />} />
                                <Row style={{color: '#a59df4', fontStyle: 'italic'}}>
                                    <LoadingOutlined style={{marginRight: '10px', color: '#a59df4'}} />
                                    <ReactMarkdown>{preLoaderText}</ReactMarkdown>
                                </Row>
                            </Row>
                        </Row>
                    </Row>
                ) : (
                    <></>
                )}
                <div ref={bottomRef} />
            </div>
        </Space.Compact>
    );
};

export default observer(MessageContainer);
