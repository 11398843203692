import {
    AI_DISRUPT_PROMPTS,
    getPromptUrl,
    getChatUrl,
    AI_DISRUPT_SAVE_CHAT,
    AI_DISRUPT_HISTORY_CHAT,
    getHistoryChatUrl,
} from '../../Core/Const/Endpoints';
import {baseClient} from '../instance';
import {ICreatePromptPayload} from './types';

export default class AiDisruptApi {
    fetchPrompts = async (searchPhrase?: string): Promise<any> => {
        return await baseClient.get(AI_DISRUPT_PROMPTS, {params: {search_phrase: searchPhrase}});
    };

    createPrompt = async (payload: ICreatePromptPayload): Promise<number> => {
        const response = await baseClient.post(AI_DISRUPT_PROMPTS, payload);
        return response.data.prompt_id;
    };

    updatePrompt = async (promptId: number, payload: ICreatePromptPayload): Promise<void> => {
        await baseClient.put(getPromptUrl(promptId), payload);
    };

    fetchPrompt = async (id: number): Promise<any> => {
        return await baseClient.get(getPromptUrl(id));
    };

    deletePrompt = async (id: number): Promise<any> => {
        return await baseClient.delete(getPromptUrl(id));
    };

    saveChat = async (payload: any): Promise<any> => {
        const response = await baseClient.post(AI_DISRUPT_SAVE_CHAT, payload);
        return response.data;
    };

    updateChat = async (chatId: string, payload: any): Promise<any> => {
        const response = await baseClient.put(getChatUrl(chatId), payload);
        return response.data;
    };

    saveHistoryChat = async (payload: any): Promise<any> => {
        const response = await baseClient.post(AI_DISRUPT_HISTORY_CHAT, payload);
        return response.data;
    };

    getHistory = async (searchPhrase?: string): Promise<any> => {
        const response = await baseClient.get(AI_DISRUPT_HISTORY_CHAT, {params: {search_phrase: searchPhrase}});
        return response.data;
    };

    getHistoryChat = async (id: number): Promise<any> => {
        const response = await baseClient.get(getHistoryChatUrl(id));
        return response.data;
    };

    updateHistoryChat = async (id: number, payload: any): Promise<any> => {
        await baseClient.put(getHistoryChatUrl(id), payload);
    };

    deleteChatFromHistory = async (id: number): Promise<any> => {
        await baseClient.delete(getHistoryChatUrl(id));
    };
}
