import {FunctionComponent} from 'react';

import {Button, Card, Form, Input} from 'antd';
import {observer} from 'mobx-react';

import {useAuth} from '../../../Services/Auth';
import './index.scss';
import s from './style.module.less';

const ProfileSettingsChangePassword: FunctionComponent = () => {
    const [form] = Form.useForm();
    const {changePassword} = useAuth();

    const handleSubmit = async () => {
        const passwordField = form.getFieldsValue(['password']);

        if (passwordField.password) {
            await changePassword(passwordField.password);
            form.resetFields();
        }
    };

    const validateMessages = {
        // eslint-disable-next-line no-template-curly-in-string
        required: '${label} не может быть пустым',
    };

    return (
        <Card title="Смена пароля" bordered={false} className={s.wrapper}>
            <Form layout="vertical" form={form} validateMessages={validateMessages}>
                <Form.Item
                    label="Новый пароль"
                    name="password"
                    rules={[{required: true}]}
                    style={{marginBottom: 0, width: '100%'}}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="Подтверждение пароля"
                    name="confirm"
                    style={{marginBottom: 0, width: '100%'}}
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                        },
                        ({getFieldValue}) => ({
                            async validator(_, value) {
                                if (!value || getFieldValue('password') === value) return await Promise.resolve();
                                return await Promise.reject(new Error('Пароли не совпадают'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={handleSubmit}
                    style={{marginTop: '20px', width: '100%'}}
                >
                    Подтвердить
                </Button>
            </Form>
        </Card>
    );
};

export default observer(ProfileSettingsChangePassword);
