import axios from './axios';

export const baseClient = axios.create();

export const openaiClient = axios.create();

export const stableDiffusionClient = axios.create();

export const bardClient = axios.create();

export const aiDisruptClient = axios.create();
